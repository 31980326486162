<template>
    <a-card>
        <a-page-header
            title="人群包详情"
        />

        <div>数据源：{{ sourceName || '-' }}</div>

        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
            class="mt-20 "
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="广告平台">
                        <a-select
                            v-model="form.adPlatformIdList"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option v-for="item in platformList" :key="item.id">{{ item.adPlatformName }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="人群包可投放状态">
                        <a-select
                            v-model="form.deliveryStatus"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option :key="1">可投放</a-select-option>
                            <a-select-option :key="0">不可投放</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>
        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :pagination='false'
            bordered
            class="mt-20"
        >
            <div slot="advertiserName">
                <div>广告主</div>
                <div class="txt">平台账套</div>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
import wordLimitInput from '@/components/wordLimitInput'

export default {
    components: {
        wordLimitInput
    },
    data() {
        return {
            sourceName: this.$route.query.sourceName,
            layout: {
                labelCol: { span: 9 },
                wrapperCol: { span: 15 }
            },
            form: {},
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '人群包',
                    dataIndex: 'audienceName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告平台',
                    dataIndex: 'adPlatformName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '人群包状态',
                    dataIndex: 'audienceStatusName',
                    customRender: (text, row) => {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '人群包可投放状态',
                    dataIndex: 'deliveryStatusName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '人群包覆盖人群数目',
                    dataIndex: 'coverNum',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    slots: { title: 'advertiserName' },
                    customRender: (text, row) => {
                        return <div>
                            <div>{ row.advertiserName || '-' }</div>
                            <div class="txt">{ row.adPlatformAccountName || '-' }</div>
                        </div>
                    }
                },
                {
                    align: 'center',
                    title: '人群包最新更新时间',
                    dataIndex: 'mtime',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            platformList: [],
        }
    },
    created() {
        this.getAdPlatformList()
        this.getDataList()
    },
    methods: {
        // 获取广告平台
        getAdPlatformList() {
            this.$api.core.pphtManage.getAdPlatformList().then(res => {
                if(res.code == 200) {
                    this.platformList = res.data
                } else {
                    console.error(`获取平台列表失败，${res.message}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {}
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            let params = {
                ...this.form,
                id: this.$route.query.id,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.materialManage.getCrowdPackDataSourceDetail(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.mt-20 {
    margin-top: 20px;
}
.txt {
    font-size: 12px;
    color: #c6c6c6;
}
</style>